import * as PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { navigate } from 'gatsby-plugin-intl'
import { graphql } from 'gatsby'

import { withCountdown, withMemo } from '../../decorators'
import Layout from '../../components/Layout'
import routes from '../../router/routes'
import useConfiguration from '../../hooks/useConfiguration'
import * as appConfiguration from '../../configuration'
import router from '../../router'


const NotFound = (props) => {
  const { pageContext, location, data } = props
  const { seoFallback } = useConfiguration(data)

  // redirect to home
  if (typeof window !== 'undefined') {
    navigate(router(routes.home.path))
  }

  const layoutProps = useMemo(() => ({
    seo: {
      lang: pageContext?.language ?? null,
      title: seoFallback?.title ?? '',
      description: seoFallback?.description?.description ?? '',
      image: appConfiguration.app.url + seoFallback?.picture?.localFile?.childImageSharp?.fluid?.src ?? '',
      url: location?.href ?? '',
      robots: 'noindex, follow',
    },
  }), [location.href, pageContext.language, seoFallback.description.description, seoFallback.picture.localFile.childImageSharp.fluid.src, seoFallback.title])

  return (
    <Layout
      {...layoutProps}
    />
  )
}

NotFound.propTypes = {
  // eslint-disable-next-line
  data: PropTypes.object,
  // eslint-disable-next-line
  pageContext: PropTypes.object,
  // eslint-disable-next-line
  location: PropTypes.object,
}

NotFound.defaultProps = {
  data: null,
  pageContext: null,
  location: null,
}

export default withCountdown(withMemo()(NotFound))

export const query = graphql`
  query notfound($language: String){
    contentfulConfiguration(node_locale: { eq: $language }) {
      ...balenciageF21Configuration
    }
    
  }
`
